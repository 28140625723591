import { CalendarIcon } from "@radix-ui/react-icons";
import { forwardRef } from "react";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";
import { Calendar } from "../Calendar/Calendar";
import { Dropdown } from "../Dropdown/Dropdown";
import { Flex } from "../Flex/Flex";
import { Grid } from "../Grid/Grid";

type DateInputProps = {
  singleDateMin: Date;
  setSingleDateMin: (date: Date) => void;
  singleDateMax: Date;
  setSingleDateMax: (date: Date) => void;
};

export const DateInput = forwardRef<HTMLElement, DateInputProps>(
  ({ singleDateMin, setSingleDateMin, singleDateMax, setSingleDateMax }) => {
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

    const singleDateComponent = (singleDate: Date) =>
      !!singleDate ? <Badge>({singleDate?.toLocaleDateString("en-US", { ...options })}</Badge> : null;

    return (
      <Grid columns="2" gap="2" width="100%">
        <Dropdown>
          <Dropdown.Trigger>
            <Button style={{ width: "100%" }} variant="surface" color="gray">
              <Flex width="100%" align="center" justify="between" overflow="hidden">
                <Flex align="center">
                  <CalendarIcon />
                  From
                  {singleDateComponent(singleDateMin)}
                </Flex>
                <Dropdown.TriggerIcon />
              </Flex>
            </Button>
            {/* </div> */}
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Calendar
              mode="single"
              selected={singleDateMin}
              onSelect={setSingleDateMin}
              disabled={{
                before: singleDateMin,
                after: singleDateMax,
              }}
            />
          </Dropdown.Content>
        </Dropdown>
        <Dropdown>
          <Dropdown.Trigger>
            {/* <div> */}
            <Button variant="surface" color="gray">
              <Flex width="100%" align="center" justify="between" overflow="hidden">
                <Flex align="center">
                  <CalendarIcon />
                  To
                  {singleDateComponent(singleDateMax)}
                </Flex>
                <Dropdown.TriggerIcon />
              </Flex>
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Content>
            <Calendar
              mode="single"
              selected={singleDateMax}
              onSelect={setSingleDateMax}
              disabled={{
                before: singleDateMin,
                after: singleDateMax,
              }}
            />
          </Dropdown.Content>
        </Dropdown>
      </Grid>
    );
  },
);
